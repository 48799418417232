import { useState, useEffect, useRef } from 'react'

import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { RigidBody} from '@react-three/rapier'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { OrbitControls, useGLTF, useAnimations } from '@react-three/drei';


import * as THREE from "three";


function Exterior(props) {


  //const {dogState} = props


const {root_url} = props

//const {setLoaded} = props

//const file_url = "STORE_GLB/meshes/temp/exterior.glb"

//const file_url = "STORE_GLB/meshes/exterior.glb"

const file_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/ClayDog/Dog.glb'

 const gltf = useLoader(GLTFLoader, file_url)

 const modelRef = useRef()

 const [scene, setScene] = useState()


 let mixer

 useEffect(() => {

    let model = gltf.scene

    model.traverse(function (object){
      
      if (object.isMesh){
        object.receiveShadow = true
        object.castShadow = true
      }

    })




    

    //setLoaded(true)
    setScene(model)

},[gltf])




if (gltf.animations.length) {
        mixer = new THREE.AnimationMixer(gltf.scene);
        gltf.animations.forEach(clip => {
            const action = mixer.clipAction(clip)
            action.play();
        });
    }



// useEffect(() => {

//   if (dogState == 'Talking'){
//      if (gltf.animations.length) {
//         mixer = new THREE.AnimationMixer(gltf.scene);
//         gltf.animations.forEach(clip => {
//             const action = mixer.clipAction(clip)
//             action.play();
//         });
//     }
//   }else{
//     if (gltf.animations.length) {
//         mixer = new THREE.AnimationMixer(gltf.scene);
//         gltf.animations.forEach(clip => {
//             const action = mixer.clipAction(clip)
//             action.reset();
//         });
//     }
//   }

// },[dogState])


    
    


  useFrame((state, delta) => {
        mixer?.update(delta)
    })


// useFrame(({ clock }) => {
//   modelRef.current.rotation.y = clock.getElapsedTime() * 0.1

//   //console.log(modelRef)
// })


/*
useEffect(() => {

  setLoaded(true)

},[])
*/
 

return(
  
  <>


  {scene ?
    
      <primitive
        ref={modelRef}
        object={gltf.scene}

        scale={[2,2,2]}

        rotation={[0.2,0,0]}

        position={[0.1,0.1,0]}
      
      />
  
   :null}


  </>
  
  )
}

export default Exterior;
